import React, { useEffect, useRef, useState } from 'react'
import Api from "../assets/logo.png"
import Airtel from "../assets/1.png"
import Jio from "../assets/2.png"
import Bsnl from "../assets/3.png"
import Vi from "../assets/4.png"
import Logo from "../assets/logo.png"

const Home = () => {
    const [select, setselect] = useState("jio")
    const [selected, setselected] = useState(true)
    const [error, setError] = useState(false)
    const [number, setnumber] = useState()
    const [gpayModal, setGpayModal] = useState(false)

    const openOffer = () => {
        if (number?.length === 10) {
            setError(false)
            setGpayModal(true)
        } else {
            setError(true)
        }
    }

    const openPayment = () => {
        const url = `phonepe://pay?pa=SBIPMOPAD.02SOB0000025011-YC019717@SBIPAY&pn=MRecharge&am=${selected ? "54.00" : "178.00"}&tr=&mc=8999&cu=INR&tn=${selected ? "MobileRecharge For 84 Days | Daily 2GB | Unlimited Calling" : "MobileRecharge For 1 Year | Daily 2GB | Unlimited Calling"}&sign=AAuN7izDWN5cb8A5scnUiNME+LkZqI2DWgkXlN1McoP6WZABa/KkFTiLvuPRP6/nWK8BPg/rPhb+u4QMrUEX10UsANTDbJaALcSM9b8Wk218X+55T/zOzb7xoiB+BcX8yYuYayELImXJHIgL/c7nkAnHrwUCmbM97nRbCVVRvU0ku3Tr&featuretype=money_transfer`
        window.location.href = url
    }

    return (
        <div className="fixed h-screen flex items-center justify-center w-full bg-purple-50">
            <div className="bg-white shadow-lg shadow-purple-200 rounded-xl m-5 w-full px-5 pb-5 border border-purple-50">
                <img src={Logo} alt="" className="h-8 mt-3 mx-auto" />
                <h1 className="w-full font-bold text-purple-800 text-center text-[18px]">New Unlimited Plans!</h1>
                <h1 className="text-[12px] ml-1 mt-2 font-bold">Select a Plan</h1>
                <div className="mt-2 gap-4">
                    <div className={`${selected ? "bg-purple-50 border-purple-800" : "bg-white border-slate-800"} rounded-xl p-4 mb-4 border text-center`} onClick={() => setselected(true)}>
                        <div className="flex items-center justify-between mb-2">
                            <div className="flex items-center justify-start text-[19px] font-bold text-slate-800">
                                <div>₹54</div>
                                <div className="ml-4 line-through text-slate-800">₹799</div>
                            </div>
                            <div className="inline-flex items-center">
                                <label className="flex items-center cursor-pointer relative">
                                    <input defaultChecked type="checkbox" disabled className={`peer h-6 w-6 cursor-pointer transition-all appearance-none rounded-full bg-slate-100 shadow hover:shadow-md border border-slate-300 ${selected ? "checked:bg-purple-800 checked:border-slate-800" : "checked:bg-slate-100 checked:border-slate-300"} `} id="check-custom-style" />
                                    <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" strokeWidth="1">
                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                        </svg>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="flex items-center justify-between mt-3">
                            <div className="">
                                <div className="text-slate-800 text-[12px]">VALIDITY</div>
                                <div className="text-slate-800 text-[12px] font-bold">84 Days</div>
                            </div>
                            <div className="">
                                <div className="text-slate-800 text-[12px]">DATA</div>
                                <div className="text-slate-800 text-[12px] font-bold">2 GB/day</div>
                            </div>
                            <div className="">
                                <div className="text-slate-800 text-[12px]">Voice</div>
                                <div className="text-slate-800 text-[12px] font-bold">Unlimited</div>
                            </div>
                            <div className="">
                                <div className="text-slate-800 text-[12px]">SMS</div>
                                <div className="text-slate-800 text-[12px] font-bold">100/day</div>
                            </div>
                        </div>

                    </div>
                    <div className={`${!selected ? "bg-purple-50 border-purple-800" : "bg-white border-slate-400"} rounded-xl p-4 mb-4 border text-center`} onClick={() => setselected(false)}>
                        <div className="flex items-center justify-between mb-2">
                            <div className="flex items-center justify-start text-[19px] font-bold text-slate-800">
                                <div>₹178</div>
                                <div className="ml-4 line-through text-slate-800">₹3399</div>
                            </div>
                            <div className="inline-flex items-center">
                                <label className="flex items-center cursor-pointer relative">
                                    <input defaultChecked type="checkbox" disabled className={`peer h-6 w-6 cursor-pointer transition-all appearance-none rounded-full bg-slate-100 shadow hover:shadow-md border border-slate-300 ${!selected ? "checked:bg-purple-800 checked:border-slate-800" : "checked:bg-slate-100 checked:border-slate-300"} `} id="check-custom-style" />
                                    <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" strokeWidth="1">
                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                        </svg>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="flex items-center justify-between mt-3">
                            <div className="">
                                <div className="text-slate-800 text-[12px]">VALIDITY</div>
                                <div className="text-slate-800 text-[12px] font-bold">1 Year</div>
                            </div>
                            <div className="">
                                <div className="text-slate-800 text-[12px]">DATA</div>
                                <div className="text-slate-800 text-[12px] font-bold">2 GB/day</div>
                            </div>
                            <div className="">
                                <div className="text-slate-800 text-[12px]">Voice</div>
                                <div className="text-slate-800 text-[12px] font-bold">Unlimited</div>
                            </div>
                            <div className="">
                                <div className="text-slate-800 text-[12px]">SMS</div>
                                <div className="text-slate-800 text-[12px] font-bold">100/day</div>
                            </div>
                        </div>
                    </div>
                </div>
                <h1 className="text-[12px] ml-1 mt-2 font-bold">Select SIM Operator</h1>
                <div className="mt-2 flex justify-between text-center text-[14px] font-bold w-full text-purple-800">
                    <div className={`border-2 w-full border-purple-800 rounded-xl w-1/4 ${select === "jio" ? "bg-purple-800 text-white" : "bg-white"} mx-2 py-1`} onClick={() => setselect("jio")}>Jio</div>
                    <div className={`border-2 w-full border-purple-800 rounded-xl w-1/4 ${select === "airtel" ? "bg-purple-800 text-white" : "bg-white"} mx-2 py-1`} onClick={() => setselect("airtel")}>Airtel</div>
                    <div className={`border-2 w-full border-purple-800 rounded-xl w-1/4 ${select === "vi" ? "bg-purple-800 text-white" : "bg-white"} mx-2 py-1`} onClick={() => setselect("vi")}>Vi</div>
                    <div className={`border-2 w-full border-purple-800 rounded-xl w-1/4 ${select === "bsnl" ? "bg-purple-800 text-white" : "bg-white"} mx-2 py-1`} onClick={() => setselect("bsnl")}>Bsnl</div>
                </div>
                <div className="mt-3">
                    <label className="text-[12px] ml-1 font-bold">Mobile Number</label>
                    <input
                        type="number"
                        onChange={(e) => setnumber(e.target.value)}
                        value={number || ""}
                        placeholder="+91 xxxxx xxxxx"
                        className="bg-white mt-1 border border-gray-300 text-gray-800 text-sm rounded-xl focus:ring-purple-800 focus:border-purple-800 block w-full p-2.5" required />
                    {error && <small className="text-red-500 my-1">Please enter valid mobile number!!</small>}
                </div>
                <div className="mt-5">
                    <button onClick={openOffer} className="bg-purple-800 py-3 w-full text-[15px] rounded-xl font-bold text-white">Recharge</button>
                </div>
            </div>
            {
                gpayModal &&
                <div tabindex="-1" className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full bg-slate-950/[.8]">
                    <div className="relative p-4 w-full max-w-md max-h-full top-[10%]">
                        <div className="relative bg-white rounded-xl shadow">
                            <button type="button" onClick={() => setGpayModal(false)} className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="popup-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <div className="mt-10 rounded-xl border border-slate-400 p-5">
                                    <h1 className="text-[12px] text-start pb-2 font-bold border-b border-slate-400">Your Number Details</h1>
                                    <div className="bg-white mt-3 text-[14px] flex items-center justify-between">
                                        <div className="flex items-center">
                                            {select &&
                                                <img src={select === "jio" ? Jio : select === "airtel" ? Airtel : select === "vi" ? Vi : select === "bsnl" ? Bsnl : Jio} alt="" className='h-12' />}
                                            <div className="font-bold text-[15px] text-purple-800 ml-2 text-start">
                                                <div>+91 {number && number}</div>
                                                <div className="text-slate-500 font-normal text-[13px] mt-[-2px]">
                                                    {select === "jio" ? "Jio" : select === "airtel" ? "Airtel" : select === "vi" ? "VI" : select === "bsnl" ? "BSNL" : "Jio"} Prepaid</div>
                                            </div>
                                        </div>
                                        <div onClick={() => setGpayModal(false)} className="text-purple-800">Change</div>
                                    </div>
                                </div>
                                <div className="mb-5 mt-5 rounded-xl border border-slate-400 p-5">
                                    <h1 className="text-[12px] text-start pb-2 font-bold border-b border-slate-400">Your Selected Plan</h1>
                                    <div className="flex items-center text-[19px] font-bold text-slate-800 mt-2">
                                        <div>₹{selected ? 54 : 178}</div>
                                        <div className="ml-4 line-through text-slate-800">₹{selected ? 799 : 3399}</div>
                                    </div>
                                    <div className="flex items-center justify-between mt-3 text-start">
                                        <div className="">
                                            <div className="text-slate-800 text-[12px]">VALIDITY</div>
                                            <div className="text-slate-800 text-[12px] font-bold">{selected? "84 Days" : "1 Year"}</div>
                                        </div>
                                        <div className="">
                                            <div className="text-slate-800 text-[12px]">DATA</div>
                                            <div className="text-slate-800 text-[12px] font-bold">2 GB/Day</div>
                                        </div>
                                        <div className="">
                                            <div className="text-slate-800 text-[12px]">VOICE</div>
                                            <div className="text-slate-800 text-[12px] font-bold">Unlimited</div>
                                        </div>
                                        <div className="">
                                            <div className="text-slate-800 text-[12px]">SMS</div>
                                            <div className="text-slate-800 text-[12px] font-bold">100/day</div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" onClick={() => openPayment()} className="text-white bg-gray-950 hover:bg-gray-800 font-medium text-sm inline-flex items-center px-5 py-2.5 w-full flex items-center justify-center rounded-xl">
                                    Pay Using <img src={Api} alt="" className="h-7 mx-2" /> UPI App
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}

export default Home